import 'react-app-polyfill/stable'
import 'core-js'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store, persistor } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import axios from 'axios'
import { useSelector } from 'react-redux'

const GetUserData = () => {
  const user = useSelector((state) => state.loggedUser.loggedUser)
  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL

    axios.interceptors.request.use(
      (config) => {
        if (!config.headers.Authorization) {
          const token = user.token

          if (token) {
            config.headers.Authorization = `Bearer ${token}`
            config.withCredentials = true
          }
        }

        return config
      },
      (error) => Promise.reject(error),
    )
  }, [user])
}

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <GetUserData />
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

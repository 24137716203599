import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

export const PrivateRoute = (RouteComponent) => {
  const user = useSelector((state) => state.loggedUser.loggedUser)
  if (user.token) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <RouteComponent.component />
  }

  return <Navigate to="/login" />
}

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loggedUser: {
    _id: null,
    name: '',
    lastname: '',
    email: '',
    token: '',
  },
}

export const authSlice = createSlice({
  name: 'loggedUser',
  initialState,
  reducers: {
    logIn: (state, action) => {
      state.loggedUser = action.payload
    },

    logOut: (state) => {
      state.loggedUser = initialState.loggedUser
    },
  },
})

export const { logIn, logOut } = authSlice.actions

export default authSlice.reducer
